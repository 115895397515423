<template>
  <v-row justify="center">
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="model"
      max-width="350px"
    >
      <v-card outlined>
        <v-card-title class="title">{{ title }}</v-card-title>
        <v-divider />
        <v-card-text class="pt-3">
          <FilterSchoolYear
            :withInitial="false"
            :resetFilter="resetFilter"
            :itemsMajor="majorList"
            :mandatoryMajor="isMandatoryMajor"
            isMaster
            isVertical
            notMultiple
            showMajorSelect
            @handler="getFilter"
          />
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              outlined
              small
              color="primary"
              @click="closeDialog"
              class="mr-2"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              class="gradient-primary"
              dark
              depressed
              small
              @click="postEntryClass"
              :loading="isLoading"
              :disabled="!modelEntryClass"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FilterSchoolYear from "../../components/filterSchooYear";
import { mandatoryMajor } from "@/utils/major";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    model: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    majorList: {
      type: Array,
      default: () => []
    }
  },
  components: { FilterSchoolYear },
  data() {
    return {
      modelEntryClass: false,
      resetFilter: false
    };
  },
  watch: {
    model(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.resetFilter = true;
        });
      }
    }
  },
  computed: {
    ...mapGetters(["g_master_data_siswa"]),
    isMandatoryMajor() {
      return mandatoryMajor();
    }
  },
  methods: {
    getFilter(res) {
      if (
        typeof res.filter.class == "number" &&
        typeof res.filter.grade == "number" &&
        res.filter.school_year.length > 0
      ) {
        this.modelEntryClass = true;
      }
      this.$emit("getData", res);
    },
    concatString: item => item.grade + " / " + item.name,
    dialogTambahSiswa() {
      this.$store.commit("TOGGLE_DIALOG_CALON_SISWA");
    },
    closeDialog() {
      this.modelEntryClass = false;
      this.resetFilter = false;
      this.$emit("isOpen");
    },
    postEntryClass() {
      this.resetFilter = false;
      this.$emit("postEntryClass");
    }
  }
};
</script>
